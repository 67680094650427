// File: custom-renderer.js
// Import Highway
import Highway from '@dogstudio/highway/src/highway';
import gsap from 'gsap'

class Index extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        const next_project_background = document.querySelector('.js-next-project')
        const site_background = document.querySelector('.js-site-background')
        window.scrollTo(0, 0);
        gsap.to(site_background,{
            height: '100%',
            position: 'fixed',
            duration: .8
        })
        gsap.set(next_project_background,{
            opacity:0
        })
	}
	onLeave() {
        const next_project_background = document.querySelector('.js-next-project')        
        gsap.set(next_project_background,{
            opacity:1
        })
	}
}

// Don`t forget to export your renderer
export default Index;