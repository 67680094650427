// Highway
import Highway from '@dogstudio/highway/src/highway';

// GSAP
import gsap from 'gsap';

// Fade
class OverlapIndexProjects extends Highway.Transition {

    in ({ from, to, done, trigger }) {
        const els = document.querySelectorAll('.home_img')
        //Reset position scroll page
        window.scrollTo(0, 0);
        //Remove DOM elements previous page
        from.remove() 
        gsap.set(els,{
            y: window.innerHeight * 2
        })
        gsap.to(els,{
            y:0,
            duration:.8,
            ease: 'Power2.easeOut',
            onComplete: () => {
                const background = document.querySelector('.js-site-background')
                    if (background.classList.contains('bg-beige')) {
                        background.classList.remove('bg-beige')                        
                    }
                    
                    done()
            }
        })
    }

    out({ done, from }) {
        const background = document.querySelector('.js-site-background')        
        const els = document.querySelector('.js-page-content')
        gsap.to(els,{
            y: window.innerHeight * 2,
            duration:.8,
            ease: 'Power2.easeIn'
        })
        gsap.to(background,{
            height: '100%',
            duration:.8,
            ease: 'Power2.easeIn',            
            onComplete: done            
        })
    }
}
export default OverlapIndexProjects;