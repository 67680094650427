import verge from 'verge';
import gsap from 'gsap'
import lodash from 'lodash'

export default class ScrollScale {
    constructor(el) {
      	this.center = window.innerHeight / 2
      	this.el = el
      	this.scale_value = 1
      	this.image = el.querySelector('.home_img_wrapper_container')
        this.window_width = window.innerWidth
        this.max = .2
        this.min = -.2
        this.scale()
    }

    init() {
      	window.addEventListener("scroll",() => {
      		this.scale()
      	})
    }

    map(value, low1, high1, low2, high2) {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }
    //sistemare max min .05 se è in mobile
    scale() {
      const elscroll = this.el.getBoundingClientRect()
      const scroll   = verge.scrollY()
      const img_center = elscroll.height / 2
      const distance = Math.abs(this.center - (elscroll.top + img_center))
      if (this.window_width < 768) {
          this.max = .05
          this.min = -.05
      } 
      const value = (this.map(distance,0,window.innerHeight,this.min,this.max))  
     
      gsap.to(this.image,{
      	scale: this.scale_value + (-value) ,
      	duration: .4
      })
    }

}