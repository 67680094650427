import verge from 'verge'
import gsap from 'gsap'

export default function() {
    const header = document.querySelector('.js-header')
    let scrollPos = 0;

    window.addEventListener('scroll', () => {
        if (window.innerWidth > 768) {
            const scroll = verge.scrollY()      
            if (scroll > 1 ) {
                header.classList.add("hidden")
            } else {
                header.classList.remove("hidden")
            }            
        }
    });

}