import Highway from '@dogstudio/highway/src/highway';
import gsap from 'gsap'

class About extends Highway.Renderer {
    onEnter() {
        const background = document.querySelector('.js-site-background')
        const next_proj_background = document.querySelector('.js-next-project')
        background.classList.add('bg-beige')
        gsap.set(next_proj_background,{
            opacity:0
        })
    }
    onLeave() {
        const about = document.querySelector('.about')
            
        gsap.to(about,{
            opacity:0,
            duration:.2
        })
    }
    onLeaveCompleted() {
        const next_proj_background = document.querySelector('.js-next-project')
        gsap.set(next_proj_background,{
            opacity:1
        })            
    }
}
export default About;