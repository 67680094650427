
// File: fade.js
// Import Highway
import Highway from '@dogstudio/highway/src/highway';

// GSAP Library
import gsap from 'gsap';

// Fade
class ScaleFade extends Highway.Transition {
  in({ from, to, done }) {
    to.classList.add("animating")
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    gsap.to(to,{ opacity: 0, scale: .95, duration: 0 })
    gsap.to(to,
      
      {
        opacity: 1,
        scale: 1,
        duration: 1,
        onComplete: () => {
          done
          to.classList.remove("animating")
        }
      }
    );
  }

  out({ from, done }) {
    // Animation
    from.classList.add("animating")
    gsap.fromTo(from,{ 
        opacity: 1,
        scale: 1 },
      {
        scale: .95,
        opacity: 0,
        duration: 1,
        onComplete: done
      }
    );
  }
}

export default ScaleFade;