// File: custom-renderer.js
// Import Highway
import Highway from '@dogstudio/highway/src/highway';
import gsap from 'gsap'

class Show extends Highway.Renderer {
    // Hooks/methods
    onEnterCompleted() {
        const site_background = document.querySelector('.js-site-background')
        const next_project_background = document.querySelector('.js-next-project')
        const half_viewport = window.innerHeight / 2
        const page = document.querySelector('.js-page-content')
        const color = page.getAttribute('data-color')
        const next_color = page.getAttribute('data-next-color')
        window.scrollTo(0, 0);   
        gsap.set(site_background,{
            height: half_viewport,
            backgroundColor: color,
            position: 'absolute'
        }) 
        gsap.set(next_project_background,{
            backgroundColor: next_color,
        }) 
	}
	onLeave() {

	}
}

// Don`t forget to export your renderer
export default Show;