import gsap from 'gsap'
import verge from 'verge'

export default function() {

    window.addEventListener('scroll', function() {
        scrollFunction()
    });

    const inViewport = (el) => {
        const bounding = el.getBoundingClientRect()
        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
            return true
        } else {
            return false
        }
    }

    const getStyle = function(element, property) {
        return window.getComputedStyle ? window.getComputedStyle(element, null).getPropertyValue(property) : element.style[property.replace(/-([a-z])/g, function(g) { return g[1].toUpperCase(); })];
    };


    const scrollFunction = () => {
        const els = document.querySelectorAll('.js-color-change')
        let color = getStyle(document.querySelector('.site_background'), "background-color");
        if (els.length > 0) {
            els.forEach((el) => {
                if (inViewport(el)) {
                    color = el.getAttribute("data-color")
                }
            })
            const main_cont = document.querySelector('.site_background')
            gsap.to(main_cont, {
                duration: 1,
                backgroundColor: color
            })
        }

    }
    scrollFunction()

}