export default function() {
    const circles = document.querySelectorAll('.header_row_link_circle')
    const data_wrappers = document.querySelectorAll('[data-router-view]')
    data_wrappers.forEach((el) => {
        if (el) {
            let attribute = el.getAttribute('data-router-view')
            switch (attribute) {
                case 'about':
                    circles[1].classList.add('active')
                    circles[0].classList.remove('active')
                    break;
                case 'index':
                    circles[0].classList.add('active')
                    circles[1].classList.remove('active')
                    break;
                default:
                    circles[0].classList.remove('active')
                    circles[1].classList.remove('active')
            }            
        }
    })
}