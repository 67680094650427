// Highway
import Highway from '@dogstudio/highway/src/highway';

// GSAP
import gsap from 'gsap';

// Fade
class Overlap_next_project extends Highway.Transition {
    in ({ from, to, done, trigger }) {
        const image = to.querySelector('.project_image')
        const next_proj_background = document.querySelector('.js-next-project')
        const half_viewport = window.innerHeight / 2
        const start_point_image = window.innerHeight + half_viewport
        const speed = 1
        const el = document.querySelector('.next_project')
        //Reset position scroll page
        window.scrollTo(0, 0);
        //Remove DOM elements previous page
        from.remove()
        const site_background = document.querySelector('.js-site-background')
        const page = document.querySelector('.js-page-content')
        const color = page.getAttribute('data-color')
        const next_color = page.getAttribute('data-next-color')
        el.classList.remove('clicked')        
        const els = document.querySelectorAll(".js-main-container > div:not(.clicked)"); 
        const containers = document.querySelectorAll('.container,.row')
        gsap.set(containers,{
            opacity:0
        })
        gsap.set(els,{
            y:0
        })
        gsap.set(site_background,{            
            height: '200vh',
            position:'fixed',
            backgroundColor : `${color}`
        })
        gsap.set(next_proj_background,{
            position: 'absolute',
            height: '300px',
            backgroundColor: `${next_color}`
        })

        gsap.set(image,{
            y: start_point_image
        })                
        
        gsap.to(site_background,{
            height: `${half_viewport}px`,
            top: '0',
            position: 'fixed',
            ease: 'Power3.easeOut',
            duration:speed,
            onComplete: () => {
                gsap.set(site_background,{
                    position: 'absolute',
                    bottom: '0',
                    clearProps: 'top',
                })
            }
        })      
        gsap.to(image,{
            y: 0,
            duration:speed,
            ease: 'Power3.easeOut',
            onComplete: () => {
                gsap.to(containers,{
                    opacity: 1
                })
                done()
            }
            })
        }
    out({ trigger, done, from }) {
        const color_bg = document.querySelector('.js-next-project')
        const el = document.querySelector('.next_project')
        const tl = gsap.timeline()
        const speed = 1
        const delay = .5
        const color = color_bg.getAttribute("data-color")
        const distance = window.innerHeight
        el.classList.add("clicked")
        const els = document.querySelectorAll(".js-main-container > div:not(.clicked)");
        console.log(els)
        //Animations
        tl.to(el, {
            y: distance,
            ease: 'Power3.easeIn',
            duration: speed
        },"test")
        tl.to(color_bg,{
            height: '200vh',
            position: 'fixed',        
            ease: 'Power3.easeIn',
            duration: speed
        },"test")
        tl.to(els,{
            y: -(distance),
            duration: speed,
            onComplete: done
        }, "test")        
    }
}
export default Overlap_next_project;