import verge from 'verge'
import gsap from 'gsap'
export default function() {
    const images = document.querySelectorAll('.js-img')
    images.forEach((img) => {
    	gsap.set(img,{
    		opacity: 0,
            y: 60
    	})
    })
    document.addEventListener('scroll', () => {
           
            images.forEach((img) => {
               const img_verge = verge.inViewport(img,-300)
                if (img_verge && !img.loaded) {
                    img.loaded = true
                    gsap.to(img, {
                        opacity: 1,
                        y: 0,
                        duration: .8
                    })
                }
            })
    })

}