import gsap from 'gsap'
export default function() {
	const loader = document.querySelector('.loader')
	//loader.classList.remove('loaded')	
	const img = loader.querySelector('img')
	const tl = gsap.timeline()
	tl.to(img,{
		opacity:1,
		duration:1
	})
	.to(loader,{
		opacity:0,
		duration:.5,
		onComplete: () => {
			loader.classList.add('loaded')
		}
	})

}