// Highway
import Highway from '@dogstudio/highway/src/highway';

// GSAP
import gsap from 'gsap';

// Fade
class OverlapAbout extends Highway.Transition {

    in ({ from, to, done, trigger }) {

        //Reset position scroll page
        window.scrollTo(0, 0);
        //Remove DOM elements previous page
        from.remove()
        const about = document.querySelector('.about')
        const about_content = document.querySelector('.about_content')
        gsap.set(about_content,{
            opacity:0
        })
        gsap.to(about_content,{
            opacity:1,
            duration:.4,
            onComplete: () => {
                about.classList.add('bg-beige')
                done()
            }
        })


    }

    out({ done, from }) {
        const background = document.querySelector('.js-site-background')        
        const els = document.querySelector('.js-page-content')
        const color = "#CCBDB4"
        gsap.to(els,{
            y: window.innerHeight * 2,
            duration:.8,
            ease: 'Power2.easeIn'
        })        
        gsap.to(background,{
            height: '100%',
            duration:.8,
            ease: 'Power2.easeIn',
            backgroundColor:color,
            onComplete: done
        })
    }
}
export default OverlapAbout;