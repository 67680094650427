// Highway
import Highway from '@dogstudio/highway/src/highway';

// GSAP
import gsap from 'gsap';

// Fade
class Overlap extends Highway.Transition {

    in ({ from, to, done, trigger }) {
        const site_background = document.querySelector('.js-site-background')
        const half_viewport = window.innerHeight / 2
        trigger.classList.remove('clicked')
        const els = document.querySelectorAll('.container,.row')
        //Reset position scroll page
        window.scrollTo(0, 0);
        //Remove DOM elements previous page
        from.remove()
        const next_project_background = document.querySelector('.js-next-project')        
        const page = document.querySelector('.js-page-content')
        const color = page.getAttribute('data-color')
        const next_color = page.getAttribute('data-next-color')
        const duration = .8
        //Animations
        gsap.set(els,{
            opacity:0
        })
        gsap.set(next_project_background,{
            backgroundColor: `${next_color}`
        })
        gsap.to(site_background, {
            height: `${half_viewport}px`,
            position: 'absolute',
            duration: duration,
            onComplete: () => {
                gsap.to(els,{
                    opacity:1,
                    duration:.2
                })
                    const l = document.querySelector('.home_img_wrapper_text_link')
                    if ( l ) {
                        l.classList.add("visibile")
                    }
                done()
            }
        })
    }

    out({ trigger, done, from, to }) {
        const container = document.querySelector('.js-main-container')
        const header_height = document.querySelector('.header').getBoundingClientRect().height
        const padding = window.getComputedStyle(container, null).getPropertyValue("padding-left")
        const trigger_width = container.clientWidth - parseInt(padding) * 2
        const margin = container.getBoundingClientRect().left
        const trigger_moveX = -(trigger.getBoundingClientRect().left - margin - parseInt(padding))
        const trigger_moveY = -(trigger.getBoundingClientRect().top - header_height)
        const site_background = document.querySelector('.js-site-background')
        trigger.classList.add("clicked")
        const els = document.querySelectorAll(".js-link:not(.clicked)");
        const el = trigger.querySelector('.js-wrapper-container')
        const speed = .8
        const color = trigger.parentNode.getAttribute("data-color")
        //Animations
        gsap.to(els, {
            opacity: 0,
            duration: .2
        })
        gsap.to(el, {
            scale: 1,
            duration: speed
        })
        gsap.to(site_background, {
            backgroundColor: color,
            height: '100%',
            position: 'fixed',
            duration: speed,
        })
        gsap.to(trigger, {
            y: trigger_moveY,
            x: trigger_moveX,
            width: trigger_width,
            duration: speed,
            onComplete: done
        })
    }
}
export default Overlap;