import gsap from 'gsap'

export default class Video{
  constructor(el){
    this.paragraphs = el.querySelectorAll('p')
    this.video = el.querySelector('iframe')
    this.src = this.paragraphs[0].getAttribute('data-video')
    this.video.src = this.src
    this.direction = 0
    this.current = 0
    this.paragraphs[0].classList.add('active')
    this.span = this.paragraphs[0].querySelector('span')
    this.span.classList.add('active')
  }
  init(){
    this.paragraphs.forEach((p,i) => {
      p.addEventListener('click', (e) => {
        let active = e.target
        this.goTo(p,i)
        this.setActive(active)
      })
    })
  }
  goTo(p,index){
    if (index > this.current) {
      this.direction = 1
    } else {
      this.direction = -1
    }
    this.current = index
    this.fadeOut(p)
  }
  fadeOut(p){
    gsap.to(this.video,{
      y: this.direction * 16,
      opacity: 0,
      duration: .5,
      onComplete: () => {
        this.changeVideo(p)
      }
    })
  }

  changeVideo(p) {
     gsap.to(this.video,{
      duration: 0,
      y: -(this.direction * 16)
    })
    this.src = p.getAttribute('data-video')
    this.video.src = this.src
    this.video.addEventListener('load', this.fadeIn.bind(this))
  }

  fadeIn(){
    gsap.to(this.video,{
      y: 0,
      opacity: 1,
      duration: .5
    })
  }

  setActive(act){
    this.paragraphs.forEach((p) => {
      if (act == p) {
        p.classList.add('active')
        let span = p.querySelector('span')
        span.classList.add('active')
      } else {
        p.classList.remove('active')
        let span = p.querySelector('span')
        span.classList.remove('active')
      }
    })
  }
}
