// File: fade.js
// Import Highway
import Highway from '@dogstudio/highway/src/highway';
// GSAP Library
import gsap from 'gsap';

// Fade
class Fade extends Highway.Transition {


    in ({ from, to, trigger, done }) {
        // Reset Scroll
        window.scrollTo(0, 0);
        // Remove Old View
        from.remove();
        // Animation
        done()
    }

    out({ from, done, trigger }) {
        done()
    }

}

export default Fade;