// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import gsap from 'gsap';
import '../../scss/application.scss'
import Highway from '@dogstudio/highway/src/highway';

// Import Transitions
import Loader from './components/loader.js'
import Fade from './components/fade.js';
import Overlap from './components/overlap.js'
import Overlap_next_project from './components/overlap_next_project.js'
import ScaleFade from './components/scale_fade.js';
import Show from './components/show-renderer.js'
import Index from './components/index-renderer.js'
import About from './components/about-renderer.js'
import Color_change from './components/color_change.js';
import Header from './components/header.js';
import ScrollScale from './components/scroll_and_scale.js'
import ScrollAnimations from './components/scroll_animations.js'
import CurrentPage from './components/current_page.js'
import OverLapIndexProjects from './components/overlap_index_projects.js'
import OverLapAbout from './components/overlap_about.js'
import Video from './components/video.js'



const app = function() {
    //CurrentPage()
    Header()
    Loader()
    Color_change()
    ScrollAnimations()
    const conts_video = document.querySelectorAll('.js-video')
    conts_video.forEach((vid) => {
      const video_block = new Video(vid)
      video_block.init()
    })
    const els = document.querySelectorAll('.js-scroll-scale')
    els.forEach((el) => {
        const content = new ScrollScale(el)
        content.init()
    })
    const privacy_cookie = document.cookie.indexOf('privacy_cookie=');
    if ( privacy_cookie < 0 ) {
        
        const cookie_el = document.querySelector('.cookie_pop')
        cookie_el.classList.add("active")
        const bookie_btn = document.querySelector('.cookie_pop_close')
        bookie_btn.addEventListener("click",() => {
            document.cookie = "privacy_cookie=ok";
            cookie_el.classList.remove("active")
        })
    }

}

window.addEventListener('load', function() {
    app()
    const l = document.querySelector('.home_img_wrapper_text_link')
                    if ( l ) {
                        l.classList.add("visibile")
                    }

    let H = new Highway.Core({
        renderers: {
            show: Show,
            index: Index,
            about: About
        },
        transitions: {
            default: Fade,
            contextual: {
                overlap: Overlap,
                overlap_next_project : Overlap_next_project,
                overlap_index_projects : OverLapIndexProjects,
                overlap_about: OverLapAbout
            }
        }
    });
    H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
        app()
         if (typeof gtag !== 'undefined') {
            gtag('config', 'UA-174159682-1', {
              'page_path': location.pathname,
              'page_title': to.page.title,
              'page_location': location.href
            });
          }
    });
})
